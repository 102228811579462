<template>
<div class="home--iot">
  <div class="home--header">
      <els-text-card v-bind="cardData"></els-text-card>
    </div>
  <div class="iot-home">
    
    <div class="title">功能介绍</div>
    <div class="list-content" v-for="item of functionData" :key="item.icon">
      <div class="header">
        <div class="icon">
          <svg aria-hidden="true">
            <use :xlink:href="item.icon"></use>
          </svg>
        </div>
        <div class="text">
          <div>{{item.title1}}</div>
          <div>{{item.title2}}</div>
        </div>
      </div>
      <div class="content">
        <div class="spot-list" v-for="item1 of item.content" :key="item1">
          <div class="spot"></div>
          <div class="text">{{item1}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
  
</template>

<script>
  export default {
    name: "index",
    data () {
      return {
        functionData: [{
          icon: '#icon-shebeijieru1',
          title1: '设备接入',
          title2: '工业物联网平台支持海量设备连接上云，设备与云端进行稳定可靠地双向通信。',
          content: ['提供网关，将连接到传统和第三方系统的设备与H-IoT集成。',' 提供2G/ 3G /4G、NB-IoT、LoRaWAN、Wi-Fi等不同网络设备接入方案，解决企业异构网络设备接入管理痛点。',' 提供MQTT、CoAP、HTTP/S、OPC-UA等多种协议的设备端SDK，既满足长连接的实时性需求，也满足短连接的低功耗需求。']
        },
          {       icon: '#icon-shebeiguanli1',
            title1: '设备管理',
            title2: '工业物联网平台提供完整的设备生命周期管理功能，支持设备注册、功能定义、数据解析、远程配置、固件升级、远程维护、实时监控、分组管理、设备删除等功能。',
            content: ['提供设备物模型，基于设备生命周期事件的触发操作。例如，如果设备处于联机/脱机状态，则创建警报。','将遥测或属性从设备复制到相关资源，以便可以聚合遥测。例如，来自多个设备的数据可以聚合到相关资产中。','提供数据存储能力，方便用户海量设备数据的存储及实时访问。', ' 提供设备多租户管理机制，安全可靠。']
          },
          {       icon: '#icon-shishixiangying',
            title1: '规则引擎',
            title2: '工业物联网平台规则引擎是一个易于使用的框架，用于构建基于事件的工作流。',
            content: ['操作简单，可通过拖拽操作配置基于事件的工作流。','功能强大，配有大量特殊功能的节点。','支持复制、导入、导出等快捷操作。 ']
          },
          {   icon: '#icon-xuanzesuanfa',
            title1: '边缘计算',
            title2: '工业物联网平台边缘计算将云端的能力下沉到边缘侧，解决边缘实时性、可靠性、运维经济性等方面遇到的问题。 对于运维，云端提供一体化的运维工具，可以在云端集中运维，降低运维成本，提升运维效率。',
            content: ['快速编程，可通过场景规则、函数计算、流数据分析提升开发应用，并将其部署到边缘节点。','可在本地对设备数据进行聚合清洗、分级处理。处理后的数据进入大数据平台进行分析存储。']
          },
        ],
        cardData:{
          title:'工业物联网平台',
          desc:'工业物联网平台是一个健壮、可扩展和用户友好的平台，为设备提供安全可靠的连接通信能力，向下连接海量工业设备，支撑设备数据采集、数据分析；向上提供云端API，服务端通过远程调用将指令下发至设备端，实现远程控制。工业物联网平台也提供了其他增值能力，如网关、设备管理、规则引擎、边缘计算、可视化报表等，为各类IoT场景、行业客户和开发者赋能',
          // image:'https://www.hanyuniip.cn/img/image-iot.bbe023ec.png',
          image:'/static/image/image-iot.png',
          imageWidth:'300px',
          layout:'right-left',
          titleColor:'#fff',
          descColor:'#ddd',
        }
      }
    },
    methods: {
      bannerShowControl(v) {
        // parent为basicContainer
        const parent = this.$parent;
        parent.bannerShowControl(v);
      },
    },
    mounted () {
      this.bannerShowControl(true);
    },
    beforeDestroy () {
      this.bannerShowControl(false);
    }
  }
</script>

<style scoped lang='scss'>
.home--iot{
  box-sizing: border-box;
  padding:2px;
}
.home--header{
  height:260px;
  background:#081b33;
}
  .iot-home {
    padding: 56px 10.41%;
    .title {
      font-size:32px;
      font-weight:500;
      text-align: center;
    }
    .list-content {
      background: #FFF;
      margin-top: 24px;
      .header {
        display: flex;
        align-items: center;
        padding: 24px;
        border-bottom: 1px solid #DDD;
        .icon svg{
          width: 40px;
          height: 40px;
        }
        .text {
          padding-left: 16px;
          font-size: 16px;          
          color: #666;
          div:last-child {
            font-size: 14px;
            font-weight:400;
            line-height: 36px;
            color: rgba(51, 51, 51, 0.65);
          }
        }
      }
      .content {
        padding: 24px;
        .spot-list {
          display: flex;
          align-items: center;
          line-height:28px;
          .spot {
            width: 4px;
            height: 4px;
            border-radius: 2px;
            margin-right: 4px;
            background: #0091FF;
          }
          .text {
            color:rgba(51,51,51,0.65);
            font-size:14px;
          }
        }
      }
    }
  }
</style>
